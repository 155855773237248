import React from 'react';
import { Play, Volume2, Maximize2 } from 'lucide-react';
import Image from 'next/image';
import { Button } from '@/shared/shadcn/ui/button';

const listTutorial = [
  {
    id: 1,
    key: 'Tutorial 01',
    label: 'Uploading Documents',
    description:
      'Ready to get started? Uploading your documents is a breeze! In this tutorial, we’ll guide you step-by-step to ensure the process is quick and hassle-free. Let’s dive in so you can start signing your important documents right away!',
  },
  {
    id: 2,
    key: 'Tutorial 02',
    label: 'Signing Documents',
    description:
      'Once your document is uploaded, it’s time to sign! This tutorial will show you how to use various signature features, including images, stamps, and e-seals. Let’s make your document official!',
  },
  {
    id: 3,
    key: 'Tutorial 03',
    label: 'Sharing Documents',
    description:
      'Is your document signed? Now it’s time to share it! In this tutorial, you’ll learn how to quickly share documents with colleagues or clients. Let’s make collaboration easier!',
  },
];

interface TutorialSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const TutorialSection: React.FC<TutorialSectionProps> = ({ refSection }) => {
  return (
    <section
      className='pb-[120px] md:pb-[140px] px-4 dark:bg-dark bg-white'
      data-index='tutorial'
      ref={refSection}
    >
      <div className='mx-auto max-w-7xl'>
        <div className='flex flex-col items-center'>
          <div className='flex justify-center mb-[24px]'>
            <div className='flex justify-center w-fit items-center py-1 px-[27px] border-[1px] rounded-full'>
              <span className='text-[40px] -mt-2 font-vivaldi'>e</span>
              <span className='font-semibold mr-1'>-Signature </span> Tutorial
            </div>
          </div>

          <h2 className='text-center mb-2'>
            <span className='text-3xl md:text-4xl font-semibold'>
              Easy guide{' '}
            </span>
            <span className='text-[42px] md:text-[52px] font-medium font-vivaldi'>
              to{' '}
            </span>

            <span className='text-3xl md:text-4xl font-semibold'>
              {' '}
              using{' '}
              <span className='text-[42px] md:text-[52px] font-medium font-vivaldi'>
                e
              </span>
              -Signature
            </span>
          </h2>
          <p className='text-center text-gray-600 mb-9'>
            Simple steps to get you signing in no time.{' '}
          </p>

          {listTutorial.map((tutorial, index) => (
            <div
              key={index}
              className={`flex flex-col items-center ${
                index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
              } ${
                index === listTutorial.length - 1 ? '' : 'mb-11'
              } gap-3 border-[1px] rounded-3xl px-3 py-2 md:px-8 md:py-11`}
            >
              <div className='md:w-1/2 md:pr-8'>
                <div className='relative bg-gray-900 rounded-lg overflow-hidden'>
                  <Image
                    src='/placeholder.svg?height=300&width=400'
                    alt='Document upload interface'
                    width={400}
                    height={300}
                    className='w-full h-auto'
                  />
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <div className='bg-blue-600 rounded-full p-4'>
                      <Play className='w-8 h-8 text-white' />
                    </div>
                  </div>
                  <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 flex items-center justify-between'>
                    <div className='flex items-center space-x-2'>
                      <Play className='w-4 h-4' />
                      <span>12:50</span>
                    </div>
                    <div className='flex items-center space-x-4'>
                      <Volume2 className='w-4 h-4' />
                      <Maximize2 className='w-4 h-4' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='md:w-1/2'>
                <div className='inline-block px-2 md:px-7 py-1 md:py-2 border-[1px] rounded-full text-sm md:text-base font-medium mb-4 md:mb-10'>
                  {tutorial.key}
                </div>
                <h2 className='text-xl md:text-2xl font-semibold mb-1 md:mb-3'>
                  {tutorial.label}
                </h2>
                <p className='text-gray-600 mb-4 md:mb-12'>
                  {tutorial.description}
                </p>
                <Button className='text-white'>Watch Tutorial</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TutorialSection;
