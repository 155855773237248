'use client';

import * as React from 'react';
import BaseContainerLandingPage from '@/features/landing-page/components/BaseContainerLandingPage/BaseContainerLandingPage';
import { useTheme } from 'next-themes';
import { usePathname } from 'next/navigation';

export default function Home() {
  const pathname = usePathname();
  const { setTheme } = useTheme();

  React.useEffect(() => {
    if (pathname === '/') {
      setTheme('light');
    }
  }, [pathname]);

  return <BaseContainerLandingPage />;
}
