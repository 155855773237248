import React from 'react';
import { Button } from '@/shared/shadcn/ui/button';

type HomeSectionProps = {
  handleGetStarted: () => void;
  refSection?: React.RefObject<HTMLElement>;
};

const HomeSection: React.FC<HomeSectionProps> = ({
  handleGetStarted,
  refSection,
}) => {
  return (
    <>
      <main
        className='container mx-auto px-4 pt-[175px] text-center'
        data-index='home'
        ref={refSection}
      >
        <div className='max-w-2xl mx-auto pb-[40px] md:pb-[64px]'>
          <h1 className='text-5xl md:text-6xl font-semibold mb-6 font-neue text-[#1A1A1A]'>
            Effort
            <span className='text-[#666666] text-5xl md:text-6xl font-medium font-neue'>
              less
            </span>{' '}
            and Safe Digital Signatures
          </h1>

          <p className='text-gray-600 mb-16 font-neue'>
            Revolutionize the way you sign documents with our QR Code
            e-Signature.
          </p>
          <Button
            className='text-lg px-[20px] py-[16px]'
            style={{
              borderRadius: '30px',
            }}
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </div>
      </main>
    </>
  );
};

export default HomeSection;
