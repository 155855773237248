import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/shadcn/ui/accordion';
import React from 'react';

type Props = {};

const ListFaq = [
  {
    question: 'What is Effortless e-Signatures?',
    answer:
      'Effortless e-Signatures is a digital signature solution that allows you to sign documents quickly and securely, streamlining your workflow without the need for printing or scanning.',
  },
  {
    question: 'How does QR Code Security work?',
    answer:
      'QR Code Security adds an extra layer of protection to your documents by generating unique QR codes for each signed document, ensuring authenticity and preventing tampering.',
  },
  {
    question: 'What storage options are available?',
    answer:
      'We offer large storage options, allowing you to save and manage all your signed documents in one secure location, making retrieval and sharing easy.',
  },
  {
    question: 'Can I customize the ready-to-use templates?',
    answer:
      'Yes! Our templates are fully customizable to meet your specific needs, so you can create professional documents that align with your brand.',
  },
  {
    question: 'Is e-materai and e-stamps included in the service?',
    answer:
      'Yes, our service includes e-materai and e-stamps, making it convenient for you to add legal validation to your documents without any hassle.',
  },
];

const FAQSection = (props: Props) => {
  return (
    <div className='container mx-auto 2xl:px-4 px-[64px] pb-[120px] md:pb-[140px] dark:bg-dark bg-white'>
      <div className='mx-auto max-w-7xl'>
        <h2 className='text-center mb-2'>
          <span className='text-3xl md:text-4xl font-bold'>Frequently ask</span>
          <span className='text-3xl md:text-4xl font-medium italic'>e</span>

          <span className='text-3xl md:text-4xl font-bold'>
            d qu
            <span className='text-3xl md:text-4xl font-medium italic'>e</span>
            stions
          </span>
        </h2>
        <p className='text-center text-gray-600 mb-9'>
          Everything you need to know about our digital signature solutions
        </p>

        <Accordion type='single' collapsible className='w-full'>
          {ListFaq.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger className='text-left py-2 md:py-5 md:text-2xl'>
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className='px-4 py-2'>
                <p className='text-gray-600 text-xs md:text-base'>
                  {faq.answer}
                </p>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>

        <div className='font-semibold text-[10px] md:text-base mt-3 md:mt-8'>
          Didn't find an answer to your question? You can always check our list
          of essential information here or submit your question to our support
          team{' '}
          <a
            href='https://talentinsider.com'
            className='text-[#1D4F84] underline cursor-pointer text-[10px] md:text-base'
            target='_blank'
          >
            customer@talentinsider.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
